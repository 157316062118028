import { Box, Button, List, TextField } from "@mui/material";
import { NavLink } from "react-router-dom";
import imgeLogo from "../assets/imgLogo.jpeg";
import amrutLogo from "../assets/logo/ajadi_amrut_mohatsav.png"
import "../stylecss/navbarStyle.css";
import { MdOutlineArrowDropDown } from "react-icons/md";
// import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import imglogo1 from "../assets/logo/logo-removebg-preview.png";
import { useEffect, useState } from "react";
import axios from "axios";

const LandingNavbar = (props) => {
  const [totalApplicantCount, setTotalApplicantCount] = useState();
  const [Loading, setLoading] = useState(true)

  const gettotalApplicantCount = async () => {
    try {
      const respo = await axios.get(`https://evehicleadmin.mshfdc.co.in/api/specialSeller/public/count`);
      console.log('countRespo----', respo.data)
      setTotalApplicantCount(respo.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setTotalApplicantCount(error.message)
      console.log('errorCount---', error)
    }
  };

  useEffect(() => {
    // gettotalApplicantCount();
  }, []);

  console.log('totalApplicantCount---', totalApplicantCount)


  // console.log(props.originalFontSize);
  return (
    <>
      <Box>
        <Box className="navTop-box">
          <List
            className={`toplist-div ${props.colorMode === "black"
              ? "purpal"
              : props.colorMode === "white"
                ? "geen"
                : "orange"
              }`}
            style={{ fontSize: `${props.fontSize}px` }}
          >
            <div className="navTop-text d-flex">
              <li className="d-flex mt-3 ms-3">
                <NavLink
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  Text Size
                </NavLink>
                <div className="fontsizebtn d-flex ms-2">
                  <p className="p-tag-btn1" onClick={props.originalFontSize}>
                    A
                  </p>
                  <p className="p-tag-btn2" onClick={props.FontSizeTwenty}>
                    A+
                  </p>
                  <p className="p-tag-btn3" onClick={props.IncreaseFontSize}>
                    A++
                  </p>
                </div>
              </li>
              <li className="ms-5 d-flex mt-3">
                <NavLink
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  Theme
                </NavLink>
                <div className="colorchangebtn d-flex ms-2">
                  <p
                    className="ptag-color1"
                    onClick={() => {
                      props.colorThemeSelector("purple");
                    }}
                  ></p>
                  <p
                    className="ptag-color2"
                    onClick={() => {
                      props.colorThemeSelector("black");
                    }}
                  ></p>
                  <p
                    className="ptag-color3"
                    onClick={() => {
                      props.colorThemeSelector("orange");
                    }}
                  ></p>
                </div>
              </li>
            </div>

            <div className="ms-3">
              <li className="nav-list">
                <NavLink>Helpline No.: +918031404839</NavLink>
              </li>

              <li className="nav-list ms-5 ps-3">
                <NavLink>Helpline Email.: evehiclehelpdesk@gmail.com</NavLink>
              </li>
            </div>
          </List>
        </Box>

        {/* <div className="">
          <marquee scrollmount="10" direction="left" attribute_name="attribute_value" attributes style={{ color: 'red', fontSize: '24px', fontWeight: '500', borderBottom: '1px solid gray', backgroundColor: 'aliceblue' }} >
            There are still some applicants to fill the form. However, complaints have been received regarding applicants not getting enough time to get the documents required to fill the form. Therefore, the deadline for submission of applications for E-Vehicles in the financial year 2023-2024 is being <span style={{ color: 'green' }}>extended till 05.00 pm on 08.01.2024.</span>
          </marquee>
        </div> */}

        <div className="total-applicant-wrapper">
          <h5>Total e Vehicle Applications Submitted Successfully till <span className="total-count-date">8/01/2024</span> : <span>45389</span></h5>
        </div>

        {/* <div className="total-applicant-wrapper">
          <h5>Total e Vehicle Applications Submitted Successfully till <span className="total-count-date">{Loading ? <span>Loading...</span> : totalApplicantCount?.date ? new Date(totalApplicantCount?.date).getDate()-1 + "/" + (new Date(totalApplicantCount?.date).getMonth() + 1) + "/" + new Date(totalApplicantCount?.date).getFullYear() : totalApplicantCount} :</span> <span>{Loading ? <span>Loading...</span> : totalApplicantCount?.count ? totalApplicantCount?.count : totalApplicantCount}</span></h5>
        </div> */}



        <Box className="navsec-box">
          <div className="logo-img-div">
            <NavLink to="/">
              <img className="logo-imgg" src={imgeLogo} alt="logo-img" />
            </NavLink>
            <h6 className={`${props.colorMode === "white" ? "white" : ""}`}>
              {" "}
              महाराष्ट्र राज्य दिव्यांग वित्त व विकास महामंडळ मर्या., मुंबई
            </h6>
          </div>

          {/* <div className="inputt-div d-flex">
                        <TextField className='inputt-area' variant="standard"
                            InputProps={{
                                disableUnderline: true,
                            }}
                            placeholder="What are your looking for?"
                        />
                        <Button className={`btnn ${props.colorMode === 'black' ? 'purpal' : props.colorMode === 'white' ? 'geen' : 'orange'}`} size='small' variant="contained"><SearchOutlinedIcon sx={{ mr: 0 }} /></Button>
                    </div> */}

          <div className="logo-img-div pt-3 pb-2">
            <div className="imgbox">
            {/* "https://mshfdc.co.in/images/id-gov.png" */}
              <img src={amrutLogo} alt="img" />
            </div>
            <NavLink href="/">
              <img className="logo-imgg" src={imglogo1} alt="logo-img" />
            </NavLink>
            <h5 className={`${props.colorMode === "white" ? "white" : ""}`}>
              <b className={`${props.colorMode === "white" ? "white" : ""}`}>
                महाराष्ट्र राज्य.
              </b>{" "}
              <p> दिव्यांग कल्याण विभाग</p>
            </h5>
          </div>
        </Box>

        {/* <div className='' style={{ backgroundColor: '#6d288c', marginBottom: '2px', paddingTop: '10px', paddingBottom: '10px' }}>
          <h5 style={{ color: 'yellow', margin: '5px 0', textAlign: 'center' }}>CAUTION / सूचना</h5>
        </div> */}

        {/* <Box className='navthird-box'>
                    <List className={`${props.colorMode === 'black' ? 'purpal' : props.colorMode === 'white' ? 'geen' : 'orange'}`} sx={{ display: 'flex', textAlign: 'center', justifyContent: 'space-evenly', bgcolor: '#6d288c' }}>

                        <li className='nav-list'>
                            <NavLink>HOME</NavLink>
                        </li>
                        <li className='nav-list'>
                            <NavLink>ABOUT DISTRICT <MdOutlineArrowDropDown style={{ marginBottom: '1px' }} /></NavLink>
                        </li>
                        <li className='nav-list'>
                            <NavLink>DIRECTORY <MdOutlineArrowDropDown style={{ marginBottom: '1px' }} /></NavLink>
                        </li>
                        <li className='nav-list'>
                            <NavLink>DEPARTMENTS <MdOutlineArrowDropDown style={{ marginBottom: '1px' }} /></NavLink>
                        </li>
                        <li className='nav-list'>
                            <NavLink >TOURISM <MdOutlineArrowDropDown style={{ marginBottom: '1px' }} /></NavLink>
                        </li>
                        <li className='nav-list'>
                            <NavLink >DOCUMENTS <MdOutlineArrowDropDown style={{ marginBottom: '1px' }} /></NavLink>
                        </li>
                        <li className='nav-list'>
                            <NavLink>NOTICES <MdOutlineArrowDropDown style={{ marginBottom: '1px' }} /></NavLink>
                        </li>
                        <li className='nav-list'>
                            <NavLink>CITIZEN SERVICES </NavLink>
                        </li>

                    </List>
                </Box> */}
      </Box >
    </>
  );
};
export default LandingNavbar;
